const academyGenre = [
  {
    value: "karate",
    text: "Karate",
  },
  {
    value: "judo",
    text: "Judô",
  },
];

export default academyGenre;
