<script>
import AcademyCreateTemplate from "../templates/AcademyCreateTemplate.vue";
export default {
  components: { AcademyCreateTemplate },
  name: "AcademyCreatePage",
  computed: {
    ownerId() {
      return this.$store.state.auth.currentAuth.uid;
    },
  },
  methods: {
    async submit(payload) {
      let newAcademy = {
        ownerId: this.ownerId,
        name: payload["academy-create"].name,
        slug: payload["academy-create"].slug,
        // usar type pra ramificação de karate
        genre: payload["academy-create"].genre,
      };
      this.$store.dispatch("academy/create", newAcademy);
    },
    cancel() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <academy-create-template @cancel="cancel" @submit="submit" />
</template>
