import academyGenre from "@/constants/academy-genre";

const academyCreateForm = {
  1: {
    0: {
      type: "title",
      title: "Vamos configurar sua Academia!",
    },
    1: {
      type: "image",
      image: "no-members",
    },
    2: {
      subtitle:
        "Aqui você irá cadastrar dados da sua academia, como nome, tipo de arte marcial e sua logo.",
      type: "subtitle",
    },
    3: {
      type: "subtitle",
      subtitle: "Esses dados podem ser alterados mais tarde.",
    },
    4: {
      type: "buttons",
      buttons: ["next"],
    },
  },
  2: {
    0: {
      type: "title",
      title: "Dados da Academia",
    },
    1: {
      subtitle:
        "Aqui você irá cadastrar dados da sua academia, como nome, tipo de arte marcial e sua logo.",
      type: "subtitle",
    },
    2: {
      type: "subtitle",
      subtitle: "Insira os dados da sua academia.",
    },
    3: {
      type: "form",
      name: "academy-create",
      form: {
        name: {
          value: "",
          label: "Nome da Academia",
          name: "name",
          inputType: "text",
        },
        slug: {
          value: "",
          label: "Sigla / Apelido da Academia",
          name: "slug",
          inputType: "text",
        },
        genre: {
          value: "karate",
          label: "Gênero",
          name: "genre",
          inputType: "select",
          items: academyGenre,
        },
      },
    },
    4: {
      type: "buttons",
      buttons: ["previous", "next", "submit"],
    },
  },
};

export default academyCreateForm;
