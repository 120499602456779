<script>
import AppStepper from "@/components/atoms/AppStepper.vue";
import academyCreateForm from "@/constants/form/academy-create-form";

export default {
  components: { AppStepper },
  name: "AcademyCreateTemplate",
  data: () => ({
    step: 1,
    form: {},
    stepList: JSON.parse(JSON.stringify(academyCreateForm)),
  }),
  created() {
    if (this.hasAcademies && this.step === 1) {
      this.step = 2;
    }
  },
  methods: {
    handleChange(payload) {
      let fieldKeys = Object.keys(this.stepList[2][3].form);
      fieldKeys.forEach((field) => {
        this.stepList[2][3].form[field].value =
          payload[this.stepList[2][3].name][field];
      });
    },
    submit(payload) {
      this.$emit("submit", payload);
    },
    cancel() {
      this.$emit("cancel");
    },
    changeStep(payload) {
      this.step = payload;
    },
  },
  computed: {
    hasAcademies: function () {
      return (
        this.$store.state.user.currentUser.academies != undefined &&
        this.$store.state.user.currentUser.academies.length > 0
      );
    },
  },
};
</script>

<template>
  <section class="inner-form section-container">
    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="{ 2: '3' }"
      @submit="submit"
      @cancel="cancel"
      @change="handleChange"
    />
  </section>
</template>

<style lang="scss" scoped>
.inner-form.section-container {
  min-height: calc(var(--vh, 1vh) * 100) !important;

  // min-height: min(calc(100% + 16vh), calc(100% + 92px));
  box-sizing: border-box;
  padding: 16px !important;
}

.form-container,
form {
  min-width: 100%;
  width: 100%;
}
</style>
